import Details from './Details';

// SEASON DETAIL

document.addEventListener('DOMContentLoaded', () => {
  const detailsElements = document.querySelectorAll('.js-season');
  if (!detailsElements.length) {
    return;
  }

  const detailsArray = Array.from(detailsElements);

  // Gestion par date

  let today = new Date();
  let year = today.getFullYear();

  // Début des saisons
  let spring = new Date(year, 2, 21);  // 21 mars
  let summer = new Date(year, 5, 21);  // 21 juin
  let autumn = new Date(year, 8, 21);  // 21 septembre
  let winter = new Date(year, 11, 21); // 21 décembre

  // Gérer l'hiver qui chevauche deux années
  if (today >= winter || today < spring) { // Hiver
    detailsElements[3].classList.add('current');
  } else if (today >= spring && today < summer) { // Printemps
    detailsElements[0].classList.add('current');
  } else if (today >= summer && today < autumn) { // Été
    detailsElements[1].classList.add('current');
  } else { // Automne
    detailsElements[2].classList.add('current');
  }

  // Gestion du détail 'current'

  const allDetails = detailsArray.map((element) => {
    const detailsElement = new Details(element, {
      hasAdditionalControl: false,
    });
    detailsElement.mount();
    if (element.classList.contains('current')) {
      detailsElement.open();
    }
    return detailsElement;
  });

  function closeOthers(openElement) {
    allDetails.forEach(details => {
      if (details !== openElement && details.expanded) {
        details.close();
      }
    });
  }

  allDetails.forEach(detailsElement => {
    detailsElement.on('open', () => {
      closeOthers(detailsElement);
    });
  });

});

// TRANSCRIPT SEASON

document.addEventListener('DOMContentLoaded', () => {
  const detailsElements = document.querySelectorAll('.js-transcript');
  if (!detailsElements.length) {
    return;
  }

  const detailsArray = Array.from(detailsElements);

  const allDetails = detailsArray.map((element) => {
    const detailsElement = new Details(element, {
      hasAdditionalControl: false,
    });
    detailsElement.mount();
    return detailsElement;
  });

  function closeOthers(openElement) {
    allDetails.forEach(details => {
      if (details !== openElement && details.expanded) {
        details.close();
      }
    });
  }

  allDetails.forEach(detailsElement => {
    detailsElement.on('open', () => {
      closeOthers(detailsElement);
    });
  });
});
